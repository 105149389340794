// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete{
    text-align: center;
}

.delete_heading {
    margin: 20px 0 30px 0;
}
.delete_button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    margin: 0 auto;
}

.delete_button button {
    font-size: 24px;
    padding: 5px 20px;
    border: 2px solid #81B37A;
    border-radius: 20px;
    color: #81B37A;
    background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/Modal/Delete/delete.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,6BAA6B;AACjC","sourcesContent":[".delete{\n    text-align: center;\n}\n\n.delete_heading {\n    margin: 20px 0 30px 0;\n}\n.delete_button{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 200px;\n    margin: 0 auto;\n}\n\n.delete_button button {\n    font-size: 24px;\n    padding: 5px 20px;\n    border: 2px solid #81B37A;\n    border-radius: 20px;\n    color: #81B37A;\n    background-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

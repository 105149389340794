// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.week ul {
    padding: 5px;
    border-radius: 10px;
}

.month_time {
    padding: 7px 10px;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
}

.month_time:hover {
    opacity: 0.7;
}

.month_time:active {
opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/Modal/WeekTime/weekTime.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;AACA,YAAY;AACZ","sourcesContent":[".week ul {\n    padding: 5px;\n    border-radius: 10px;\n}\n\n.month_time {\n    padding: 7px 10px;\n    border-radius: 10px;\n    border: none;\n    margin-top: 10px;\n}\n\n.month_time:hover {\n    opacity: 0.7;\n}\n\n.month_time:active {\nopacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_header{
    display: flex ;
    justify-content: space-between ;
    padding-top: 10px;
}

.close_button {
    margin-right: 40px;
}`, "",{"version":3,"sources":["webpack://./src/Modal/AgressiyaPupil/agressiyaPupil.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,+BAA+B;IAC/B,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".modal_header{\n    display: flex ;\n    justify-content: space-between ;\n    padding-top: 10px;\n}\n\n.close_button {\n    margin-right: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

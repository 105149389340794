// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_header{
    display: flex ;
    justify-content: space-between ;
    padding-top: 10px;
}

.close_button {
    margin-right: 40px;
}

.accordion-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordion-header > p {
    margin: 0;
    margin-right: 20px;
}

.accordion-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.accordion-listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fullName {
    margin: 0;
}

.accordion-item {
    margin-top: 20px;
}

.userImage {
    border-radius: 50%;
}

.accordion-listItem {
    padding: 5px 0;
    border-bottom: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/Modal/Report/report.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,+BAA+B;IAC/B,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,8BAA8B;AAClC","sourcesContent":[".modal_header{\n    display: flex ;\n    justify-content: space-between ;\n    padding-top: 10px;\n}\n\n.close_button {\n    margin-right: 40px;\n}\n\n.accordion-header {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.accordion-header > p {\n    margin: 0;\n    margin-right: 20px;\n}\n\n.accordion-list {\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n}\n\n.accordion-listItem {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.fullName {\n    margin: 0;\n}\n\n.accordion-item {\n    margin-top: 20px;\n}\n\n.userImage {\n    border-radius: 50%;\n}\n\n.accordion-listItem {\n    padding: 5px 0;\n    border-bottom: 1px solid black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
